import React, { useContext, useRef } from 'react';
import { Grid, Box, Typography, Link, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import LogoImage from 'assets/images/distriqt-logo.png';
import { HeroSection as HeroSectionContent } from 'types/domain/content';
import parse from 'react-html-parser';

export interface HeroSectionProps {
  content: HeroSectionContent;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  box: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(6),
    },
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heroText: {
    fontSize: '3.75rem',
    fontWeight: 500,
    color: theme.palette.common.white,
    maxWidth: '90rem',
    paddingTop: '6rem',
    paddingBottom: '6rem',
    textShadow: '2px 2px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      textAlign: 'center',
    },
  },
}));

const HeroSection: React.FC<HeroSectionProps> = ({
  content,
}: HeroSectionProps): JSX.Element => {
  const styles = useStyles({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container id="hero-container">
      <Grid item xs={12}>
        <Box className={styles.box}>
          <div className={styles.logoWrapper}>
            <Link href="/">
              <img src={LogoImage} alt="distriqt logo" />
            </Link>
          </div>
          <Typography variant="h2" component="h1" className={styles.heroText}>
            {parse(content.title)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
