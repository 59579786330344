import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface FullWidthContainerProps {
  children?: React.ReactNode | undefined;
  backgroundImage?: string;
  backgroundColor?: string;
  useClipPath?: boolean;
}

interface Style {
  backgroundImage?: string;
  backgroundColor?: string;
  clipPath?: string;
  boxShadow?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
  },
}));

const FullWidthContainer: React.FC<FullWidthContainerProps> = ({
  children,
  backgroundImage,
  backgroundColor,
  useClipPath,
}: FullWidthContainerProps): JSX.Element => {
  const styles = useStyles({});
  let rootStyle: Style = {};

  if (backgroundImage) {
    rootStyle = {
      backgroundImage: `url(${backgroundImage})`,
    };
  }

  if (backgroundColor) {
    rootStyle.backgroundColor = backgroundColor;
  }

  if (useClipPath) {
    rootStyle.clipPath = 'polygon(0 0, 100% 0, 100% 88%, 0 100%)';
  }

  return (
    <div className={styles.root} style={rootStyle}>
      {children || undefined}
    </div>
  );
};

export default FullWidthContainer;
