import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    position: 'relative',
  },
}));
interface SectionContainerProps {
  id: string;
  children?: React.ReactNode | undefined;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  id,
  children,
}: SectionContainerProps): JSX.Element => {
  const styles = useStyles({});

  return (
    <section id={id}>
      <Container maxWidth="md" className={styles.sectionContainer}>
        {children || null}
      </Container>
    </section>
  );
};

export default SectionContainer;
