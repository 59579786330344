import React, { useState, useEffect, createContext } from 'react';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useScrollCheck from '../../hooks/useScrollCheck';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    flex: '1 0 auto',
    marginBottom: '5rem',
  },
  footer: {
    flexShrink: 0,
  },
}));

interface PageContainerProps {
  children?: React.ReactNode | undefined;
}

interface ScrollContext {
  isScrollMenuVisible: boolean;
}

// Context for scroll check
export const ScrollContext = createContext<ScrollContext>({
  isScrollMenuVisible: false,
});

const PageContainer: React.FC<PageContainerProps> = ({
  children,
}: PageContainerProps): JSX.Element => {
  const styles = useStyles({});
  const theme = useTheme();
  const { isScrollMenuVisible, enable, disable } = useScrollCheck();
  const [context, setContext] = useState<ScrollContext>({
    isScrollMenuVisible: false,
  });

  useEffect(() => {
    enable();

    return () => {
      disable();
    };
  }, []);

  useEffect(() => {
    setContext({ isScrollMenuVisible });
  }, [isScrollMenuVisible]);

  return (
    <>
      <ScrollContext.Provider value={context}>
        <div className={styles.page}>
          <FullWidth backgroundColor="transparent">
            <Section id="header">
              <Header />
            </Section>
          </FullWidth>
          {children || null}
        </div>
        <footer className={styles.footer}>
          <FullWidth backgroundColor={theme.palette.primary.dark}>
            <Section id="footer">
              <Footer />
            </Section>
          </FullWidth>
        </footer>
      </ScrollContext.Provider>
    </>
  );
};

export default PageContainer;
