import React, { useState, useRef, useCallback, createContext } from 'react';

interface UseScrollCheck {
  isScrollMenuVisible: boolean;
  enable(): void;
  disable(): void;
}

const useScrollCheck = (): UseScrollCheck => {
  const isScrolled = useRef<boolean>(false);
  const [update, setUpdate] = useState(0);

  const onScroll = useCallback(() => {
    const el = document.querySelector('#hero-container');

    if (el) {
      const height = el?.getBoundingClientRect().height;
      if (height > 0) {
        if (window.scrollY >= height) {
          if (!isScrolled.current) {
            isScrolled.current = true;
            setUpdate(new Date().getTime());
          }
        } else {
          if (isScrolled.current) {
            isScrolled.current = false;
            setUpdate(new Date().getTime());
          }
        }
      }
    }
  }, []);

  const enable = (): void => {
    window.addEventListener('scroll', onScroll);
  };

  const disable = (): void => {
    window.removeEventListener('scroll', onScroll);
  };

  return {
    isScrollMenuVisible: isScrolled.current,
    enable,
    disable,
  };
};

export default useScrollCheck;
