import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ImageItem } from 'types/domain/content';

export interface ImageGridProps {
  images: ImageItem[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    textAlign: 'center',
    padding: '2rem',
    '& img': {
      width: '80%',
    },
  },
}));

const ImageGrid: React.FC<ImageGridProps> = ({ images }): JSX.Element => {
  const styles = useStyles({});

  return (
    <Box py={4} className={styles.root}>
      <Grid container>
        {images.map((image, index) => {
          return (
            <Grid item xs={4} sm={3} md={3} key={`image_${index}`}>
              <div className={styles.item}>
                <img src={image.path} alt={image.altText} />
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ImageGrid;
