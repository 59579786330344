import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 8,
  },
  left: {
    backgroundColor: theme.palette.primary.main,
  },
  middle: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
  },
  right: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.45,
  },
}));

const DividerLine: React.FC = (): JSX.Element => {
  const styles = useStyles({});

  return (
    <Box my={4}>
      <Grid container className={styles.root}>
        <Grid item xs={7} className={styles.left} />
        <Grid item xs={3} className={styles.middle} />
        <Grid item xs={2} className={styles.right} />
      </Grid>
    </Box>
  );
};

export default DividerLine;
