import React, { useState, useContext, useEffect, useCallback, ReactNode } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Link,
  Hidden,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import { ScrollContext } from 'containers/PageContainer';
import LogoImage from 'assets/images/distriqt-logo.png';
import bgImage from 'assets/images/promo-background-darker.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      right: 0,
      top: 134, // 108,
    },
    [theme.breakpoints.down('sm')]: {
      top: 12,
      right: 12,
    },
  },
  scrollHeader: {
    position: 'fixed',
    top: -51,
    left: 0,
    width: '100vw',
    height: 80,
    backgroundColor: theme.palette.primary.dark,
    zIndex: 100,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.25)`,
    transition: 'top .25s ease-out',
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
  },
  rootScrolled: {
    // position: 'relative',
    position: 'absolute',
    height: 100,
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      right: 0,
      top: 26,
    },
    [theme.breakpoints.down('sm')]: {
      top: 12,
      right: 12,
    },
  },
  box: {
    color: theme.palette.common.white,
  },
  content: {
    textAlign: 'right',
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.8rem',
    marginLeft: theme.spacing(2),
  },
  drawer: {
    backgroundColor: theme.palette.primary.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  menuButton: {
    position: 'static',
    right: 0,
    top: 0,
    transform: 'scale(1.5)',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 7,
      right: 12,
    },
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  menuLink: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.8rem',
    marginLeft: theme.spacing(2),
  },
  logoImage: {
    height: 50,
    marginTop: 18,
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 37,
      marginTop: 14,
    },
  },
}));

export interface HeaderProps {}
export interface IWrapper {
  children: ReactNode;
  animated: boolean;
}

interface Style {
  backgroundImage?: string;
  backgroundColor?: string;
  clipPath?: string;
  boxShadow?: string;
  top?: number;
}

// Header when scrolled
const ScrollHeader: React.FC<IWrapper> = ({
  children,
  animated,
}: IWrapper): JSX.Element => {
  const styles = useStyles({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const bgStyle: Style = {
    backgroundImage: `url(${bgImage})`,
    top: animated ? 0 : isMobile ? -61 : -81,
  };

  return (
    <div className={styles.scrollHeader} style={bgStyle}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={3}>
            <Link href="/">
              <img className={styles.logoImage} src={LogoImage} alt="home logo" />
            </Link>
          </Grid>
          <Grid item xs={9}>
            <Box paddingTop="28px">{children}</Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  const styles = useStyles({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [animated, setAnimated] = useState(false);
  const { isScrollMenuVisible } = useContext(ScrollContext);

  useEffect(() => {
    if (isScrollMenuVisible) {
      setAnimated(true);
    } else {
      setTimeout(() => {
        setAnimated(false);
      }, 10);
    }
  }, [isScrollMenuVisible]);

  const getMenus = useCallback(() => {
    return (
      <>
        <Hidden smDown>
          <Grid container>
            <Grid item xs={12} className={styles.content}>
              <Box className={styles.box}>
                <Link href="/#services" className={styles.link}>
                  Services
                </Link>
                <Link href="/#clients" className={styles.link}>
                  Clients
                </Link>
                <Link href="/native-extensions" className={styles.link}>
                  Native extensions
                </Link>
                <Link href="/about" className={styles.link}>
                  About us
                </Link>
                <Link href="/contact" className={styles.link}>
                  Contact us
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <div className={styles.menuButton} id="menubutton">
            <IconButton size="medium" onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon fontSize="large" htmlColor="#FFFFFF" />
            </IconButton>
          </div>
          <SwipeableDrawer
            anchor="top"
            open={isDrawerOpen}
            onOpen={() => setIsDrawerOpen(true)}
            onClose={() => setIsDrawerOpen(false)}
          >
            <Grid container className={styles.drawer}>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/" className={styles.menuLink}>
                  <img className={styles.logoImage} src={LogoImage} alt="home logo" />
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/#services" className={styles.menuLink}>
                  Services
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/#clients" className={styles.menuLink}>
                  Clients
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/native-extensions" className={styles.menuLink}>
                  Native extensions
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/about" className={styles.menuLink}>
                  About us
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link href="/contact" className={styles.menuLink}>
                  Contact us
                </Link>
              </Grid>
              <Grid item xs={12} className={styles.menuItem}>
                <Link onClick={() => setIsDrawerOpen(false)} className={styles.menuLink}>
                  Close
                </Link>
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </Hidden>
      </>
    );
  }, [isDrawerOpen]);

  return (
    <>
      <header className={isScrollMenuVisible ? styles.rootScrolled : styles.root}>
        {getMenus()}
      </header>
      <ScrollHeader animated={animated}>{getMenus()}</ScrollHeader>
    </>
  );
};

export default Header;
